<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="lang=='English'?navFixed2:navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <div class="lang-box">
      <el-radio-group v-model="lang" size="medium"  @change="goHref">
        <el-radio-button label="中文"></el-radio-button>
        <el-radio-button label="English"></el-radio-button>
      </el-radio-group>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <activityAdapterFile_enUS v-if="lang=='English'" :scrollContainer="scrollContainer"></activityAdapterFile_enUS>
      <activityAdapterFile_zhCH v-else :scrollContainer="scrollContainer"></activityAdapterFile_zhCH>
    </el-scrollbar>
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
import activityAdapterFile_zhCH from "@/components/documentsBase/template/activityAdapterFile_zhCH";
import activityAdapterFile_enUS from "@/components/documentsBase/template/activityAdapterFile_enUS";
export default {
  components: { Nav,activityAdapterFile_zhCH,activityAdapterFile_enUS},
  metaInfo: {
    title: "ITGSA | 移动智能终端生态联盟 | 地震预警服务规范",
    meta: [
      {
        name: "keywords",
        content: "ITGSA,生态联盟,金标认证,地震预警服务规范",
      },
      {
        name: "description",
        content:
          "Android应用的稳定性是构建Android应用体验的重要一环，直接影响用户体验，本标准旨在构建一套Android应用稳定性测试衡量方法，牵引生态内所有应用的稳定性质量改进，构建共同的稳定和体验良好的应用生态。",
      },
    ],
  },
  data() {
    return {
      scrollContainer: HTMLElement,
      lang: this.$route.query.lang?this.$route.query.lang:'中文',
      navFixed: [
          {
            name: "一、 背景",
            isActive:0,
            class:'#1',
          },
          {
            name: "二、 activity嵌入适配",
            isActive:null,
            class:'#2',
            child:[
              {
                name: "2.1 优势和用途",
                isActive:null,
                class:'#2-1',
              },
              {
                name: "2.2 拆分窗口效果展示",
                isActive:null,
                class:'#2-2',
              },
              {
                name: "2.3 适配指导",
                isActive:null,
                class:'#2-3',
                child:[
                  {
                    name: "2.3.1 IDE要求和接入步骤",
                    isActive:null,
                    class:'#2-3-1',
                  },
                  {
                    name: "2.3.2 参数解析",
                    isActive:null,
                    class:'#2-3-2',
                  },
                  {
                    name: "2.3.3 示例配置",
                    isActive:null,
                    class:'#2-3-3',
                    child:[
                      {
                        name: "2.3.3.1 深层链接分屏",
                        isActive:null,
                        class:'#2-3-3-1',
                      },
                      {
                        name: "2.3.3.2 多重分屏",
                        isActive:null,
                        class:'#2-3-3-2',
                      },
                    ],
                  },
                  {
                    name: "2.3.4 说明和注意事项",
                    isActive:null,
                    class:'#2-3-4',
                  },
                ],
              },
            ]
          },
      ],
      navFixed2: [
          {
            name: "I. Background",
            isActive:0,
            class:'#1',
          },
          {
            name: "II. Activity embedding adaptation",
            isActive:null,
            class:'#2',
            child:[
              {
                name: "2.1 Advantages and applications",
                isActive:null,
                class:'#2-1',
              },
              {
                name: "2.2 Split window effect display",
                isActive:null,
                class:'#2-2',
              },
              {
                name: "2.3 Instructions for adaptation ",
                isActive:null,
                class:'#2-3',
                child:[
                  {
                    name: "2.3.1 IDE requirements and access steps",
                    isActive:null,
                    class:'#2-3-1',
                  },
                  {
                    name: "2.3.2 Parameter analysis",
                    isActive:null,
                    class:'#2-3-2',
                  },
                  {
                    name: "2.3.3 Configuration of examples",
                    isActive:null,
                    class:'#2-3-3',
                    child:[
                      {
                        name: "2.3.3.1 Deep link split screen",
                        isActive:null,
                        class:'#2-3-3-1',
                      },
                      {
                        name: "2.3.3.2 Multiple split screen",
                        isActive:null,
                        class:'#2-3-3-2',
                      },
                    ],
                  },
                  {
                    name: "2.3.4 Description and attentions",
                    isActive:null,
                    class:'#2-3-4',
                  },
                ],
              },
            ]
          },
      ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    goHref(value){
      this.$router.push({ path:'/documentsBase/activityAdapterFile',query:{lang:value}});
    },
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        for(let i=($(".nav-title").length-1);i>=0;i--){
          if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
            this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
            break;
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">

/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .lang-box{
    position: absolute;
    right: 0;
    top: -45px;
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
    .page-box{
      .view-box{
        padding: 10px;
        .w400, .w750{
          width: 100% !important;
        }
        .p{
          padding-left: 1rem !important;
          word-break:break-all;
        }
      }
    }
}
</style>
