<template>
  <div class="view-box">
    <h1 class="h1 text-center ">
      ITGSA Instructions for Activity Embedding Adaptation
    </h1>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="1">
      一、Background
    </h2>
    <p class="p margin-b10">
      Android has begun to support the implementation of activity embedding function through Jetpack Window since Version 12L. It has greatly improved large screen experience. Vivo, since Version 12L (including Version T), OPPO and Xiaomi, since Version T, support Jetpack Window to implement activity embedding, hoping that developers can adapt the Android activity embedding scheme as soon as possible to improve the user experience of applications on large-screen devices. When reading the adaptation document, it is recommended that developers read it together with the android WindowManager demo, so that they can become familiar with the new function of activity embedding as soon as possible.
    </p>
    <p class="p margin-b10">
      Jetpack WindowManager demo
    </p>
    <p class="p margin-b10">
      <a
        class="a"
        href="https://github.com/android/user-interface-samples/tree/main/WindowManager"
      >
        <i class="el-icon-paperclip"></i>
        https://github.com/android/user-interface-samples/tree/main/WindowManager
      </a>
    </p>
    <p class="p margin-b10">
      Instructions for Android activity embedding adaptation
    </p>
    <p class="p margin-b10">
      <a
        class="a"
        href="https://developer.android.com/guide/topics/large-screens/activity-embedding"
      >
        <i class="el-icon-paperclip"></i>
        https://developer.android.com/guide/topics/large-screens/activity-embedding
      </a>
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="2">
      II. Activity embedding adaptation
    </h2>

    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-1">
      2.1 Advantages and applications
    </h3>
    <p class="p margin-b10 padding-l30">
      Advantages
    </p>
    <p class="p margin-b10 padding-l30">
      ● Only little or no App reconfiguration is required for activity embedding. You can determine how App displays activities (side by side or stacked) by creating XML configuration file or calling Jetpack WindowManager API.
    </p>
    <p class="p margin-b10 padding-l30">
      ● The system will automatically maintain support for small screen. When the application is applied in a device with a small screen, the activities will stack with each other. On large screen, activities are displayed side by side. The system will determine display mode according to the configuration you have created (no branching logic is required).</p>
    <p class="p margin-b10 padding-l30">
      ● Activity embedding supports the change of device screen orientation, and can work seamlessly on foldable device. This function will stack and un-stack activities when the device is folded or expanded.
    </p>
    <p class="p margin-b10 padding-l30">
      Applications
    </p>
    <p class="p margin-b10 padding-l30">
      Two activities are displayed at the same time. They are automatically stacked or displayed side by side as the screen width changes (such as folding and unfolding).
    </p>
    <p class="p margin-b10 padding-l30">
      Check if it is supported during runtime. 
    </p>
    <div class="code margin-b10 margin-l30">
      <p class="p ">SplitController.getInstance().isSplitSupported();</p>  
    </div>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-2">
      2.2 Split window effect display
    </h3>
    <p class="p margin-b10">
      A and B are started at the same time.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image1.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image1.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      Full-screen A starts B, and A and B are displayed side by side.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image2.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image2.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      C will be started to the right after A and B are started (similar to the multi- window navigation mode of the application).
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image3.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image3.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      C will be started after A and B are started, and then B moves to the primary place (similar to the multi-window shopping mode of the application).
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image4.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image4.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      C will be started after A and B are started. Full screen display for C (can be used for full screen video playback, etc.)
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image5.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image5.png',
        ]"
      >
      </el-image>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-3">
      2.3 Instructions for adaptation 
    </h3>
    <h3 class="h3 margin-t20 margin-b20 nav-title" id="2-3-1">
      2.3.1 IDE requirements and access steps
    </h3>
    <p class="p margin-b10">
      IDE version requirements
    </p>
    <p class="p margin-b20">
      Android Studio Chipmunk | 2021.2.1 or higher version
    </p>
    <p class="p margin-b10">
      Add library dependencies to the App build file
    </p>
    <p class="p margin-b20">
      Libraries WindowManager and Jetpack Startup
    </p>
    <div class="code margin-b20">
      <p class="p ">implementation("androidx.window:window:1.1.0-alpha02")</p>
      <p class="p ">implementation("androidx.startup:startup-runtime:1.1.1")</p>
    </div>
    <p class="p margin-b20">
      Add configuration file: res/xml/main_split_config.xml
    </p>
    <div class="code margin-b20">
      <p class="p ">
        &lt;resources 
      </p>
        <p class="p padding-l30">xmlns:window="http://schemas.android.com/apk/res-auto"></p>
        <p class="p padding-l30">&lt;SplitPairRule  </p>
          <p class="p padding-l60">window:splitRatio="0.5"</p>
          <p class="p padding-l60">window:splitMinWidth="300dp"></p>
          <p class="p padding-l60">&lt;SplitPairFilter </p>
          <p class="p padding-l90">window:primaryActivityName=".PrimaryAct"</p>
          <p class="p padding-l90">window:secondaryActivityName=".Secondary1"/></p>
        <p class="p padding-l30">&lt;/SplitPairRule></p>
      <p class="p ">&lt;/resources></p>
    </div>
    <p class="p margin-b10">
      Add InitializationProvider in AndroidManifest.xml
    </p>
    <p class="p margin-b10">
      Note: If it is prompted that the tools tag cannot be found, xmlns needs to be added.
    </p>
    <p class="p margin-b20">
      xmlns:tools="http://schemas.android.com/tools"
    </p>
    <div class="code margin-b20">
      <p class="p ">&lt;provider</p>
        <p class="p padding-l30">android:name="androidx.startup.InitializationProvider" </p>
        <p class="p padding-l30">android:authorities="${applicationId}.androidx-startup" </p>
        <p class="p padding-l30">android:exported="false" </p>
        <p class="p padding-l30">tools:node="merge"> </p>

        <p class="p padding-l30">&lt;!-- This entry makes ExampleWindowInitializer discoverable. --> </p>
        <p class="p padding-l30"> &lt; meta-data </p>
          <p class="p padding-l60">android:name="androidx.window.sample.embedding.ExampleWindowInitializer"</p>
          <p class="p padding-l60">android:value="androidx.startup" /> </p>
      <p class="p ">&lt;/provider></p>
    </div>
    <p class="p margin-b20">
      Implement your embedding loader: ExampleWindowInitializer
    </p>
    <div class="code margin-b10">
      <p class="p ">
          public class ExampleWindowInitializer implements Initializer&lt;SplitController> {
      </p>
        <p class="p padding-l30">@Override</p>
        <p class="p padding-l30">public SplitController create(Context context) {</p>
          <p class="p padding-l60"> 
            SplitController.initialize(context, R.xml.main_split_config);
          </p>
          <p class="p padding-l60"> 
            return SplitController.getInstance();
          </p>
        <p class="p padding-l30">}</p>
        <br>
        <p class="p padding-l30">@Override</p>
        <p class="p padding-l30">public List&lt;Class&lt;? extends Initializer&lt;?>>> dependencies() {</p>
          <p class="p padding-l60"> 
            return emptyList();
          </p>
        <p class="p padding-l30">}</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b20">
      Run the App, you will see the side-by-side display of Primary Act and Secondary1.
    </p>
    <h3 class="h3 margin-t20 margin-b20 nav-title" id="2-3-2">
      2.3.2 Parameter analysis
    </h3>
    <table class="p  margin-b30" cellspacing="0" cellpadding="0">
      <tr>
        <th>
          <p>Parameter</p>
        </th>
        <th >
          <p>Description</p>
        </th>
      </tr>
      <tr>
        <td>
          <p><b>SplitPairRule</b></p>
        </td>
        <td >
          <p><b>It is used to describe split-screen pairing scenarios (containers on both sides have specific activities), corresponding to SplitPairFilter.</b></p>
        </td>
      </tr>
      <tr>
        <td>
          <p>splitRatio	</p>
        </td>
        <td >
          <p>The split screen ratio is 0.5f by default, that is, 1:1 split screen.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>splitMinWidth	</p>
        </td>
        <td >
          <p>The default value is 0. It is recommended to configure 600dp. It means that only when the width reaches the threshold of 600dp can the screen be split. It is the minimum window width of the main window for splitting.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>finishPrimaryWithSecondary		</p>
        </td>
        <td >
          <p>The value can be ”never” “always” “adjacent”，and  ”never” “always” by default. If all activities in the secondary container are finished, the activities that create split screens in the primary container will also be finished. It is not recommended that the App actively configure this item.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>finishSecondaryWithPrimary		</p>
        </td>
        <td >
          <p>The value can be ”never” “always” “adjacent”， and “always” by default. Always: If all activities in the primary container are finished, all activities in the secondary container will also be finished. It is not recommended that the App actively configure this item.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>clearTop		</p>
        </td>
        <td >
          <p>The value can be "true" or "false" is false true by default: activate the activity window split screen, there are same primary containers. If a secondary container is created, the activity in the original secondary container will be finished. App configuration is recommended to avoid multiple instances in the right split screen.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p><b>SplitPairFilter</b>		</p>
        </td>
        <td >
          <p>Split- screen pairing relation (currently Google only supports enumerating all pairing relations, but not wildcards)</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>primaryActivityName		</p>
        </td>
        <td >
          <p>Primary activity component name for split screen.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>secondaryActivityName		</p>
        </td>
        <td >
          <p>Secondary activity component name for split screen.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>secondaryActivityAction		</p>
        </td>
        <td >
          <p>Action initiated by secondary activity of splitting screen.</p>
        </td>
      </tr>
    </table>
    <table class="p  margin-b30" cellspacing="0" cellpadding="0">
      <tr>
        <th>
          <p>SplitPlaceholderRule	</p>
        </th>
        <th >
          <p>It is used to describe the placeholder scenario for split screen, corresponding to ActivityFilter.</p>
        </th>
      </tr>
      <tr>
        <td>
          <p>placeholderActivityName</p>
        </td>
        <td >
          <p>When A has started and B has not started,the activity corresponding to placeholderActivityName occupies the secondary place.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p><b>ActivityFilter</b>		</p>
        </td>
        <td >
          <p>The activity that is adaptive to the rule.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>componentName		</p>
        </td>
        <td >
          <p>Component Name</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>intentAction			</p>
        </td>
        <td >
          <p>intent</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>splitRatio			</p>
        </td>
        <td >
          <p>The split screen ratio is 0.5f by default, that is, 1:1 split screen.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>splitMinWidth		</p>
        </td>
        <td >
          <p>The default value is 0. It is recommended to configure 600dp. It means that only when the width reaches the threshold of 600dp can the screen be split. It is the minimum window width of the main window for splitting . </p>
        </td>
      </tr>
    </table>
    <table class="p  margin-b30" cellspacing="0" cellpadding="0">
      <tr>
        <th>
          <p>ActivityRule		</p>
        </th>
        <th >
          <p>Exceptional activities needing full screen display, corresponding to ActivityFilter</p>
        </th>
      </tr>
      <tr>
        <td>
          <p>alwaysExpand	</p>
        </td>
        <td >
          <p>The value can be "true" or false true: the activated activity is displayed in full screen.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p><b>ActivityFilter	</b>		</p>
        </td>
        <td >
          <p>The activity that is adaptive to the rule.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>componentName		</p>
        </td>
        <td >
          <p>Component Name</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>intentAction			</p>
        </td>
        <td >
          <p>intent</p>
        </td>
      </tr>
    </table>
    <h3 class="h3 margin-t20 margin-b20 nav-title" id="2-3-3">
      2.3.3 Configuration of examples
    </h3>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="2-3-3-1">
      2.3.3.1 Deep link split screen
    </h4>
    <p class="p margin-b20">
      Deep link split screen is namely the parallel viewport navigation mode (SplitPlaceholderRule with placeholder page)
    </p>
    <div class="code margin-b20">
      <p class="p ">&lt;resources </p>  
        <p class="p margin-l30">xmlns:window="http://schemas.android.com/apk/res-auto"></p>
        <p class="p margin-l30">&lt;!-- Automatically split the following activity pairs. --></p>
        <p class="p margin-l30">
          &lt;SplitPairRule
          <br>
          <span class="nbsp"></span>  
          window:splitRatio="0.3"
          <br>
          <span class="nbsp"></span>  
          window:splitMinWidth="600dp"
          <br>
          <span class="nbsp"></span>
          window:finishPrimaryWithSecondary="false"
          <br>
          <span class="nbsp"></span> 
          window:finishSecondaryWithPrimary="true"
          <br>
          <span class="nbsp"></span>
          window:clearTop="true">
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".MainActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName=".SecondActivity"/>
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".MainActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName=".ThirdActivity"/>
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName="*"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName="*/*"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityAction="android.intent.action.VIEW"/>
        </p>
        <p class="p margin-l30">&lt;/SplitPairRule></p>
        <br>
        <p class="p margin-l30">&lt;!--  Automatically launch a placeholder for the list activity. --></p>
        <p class="p margin-l30">
          &lt;SplitPlaceholderRule
          <br>
          <span class="nbsp"></span>  
          window:placeholderActivityName=".SplitActivityPlaceholder"
          <br>
          <span class="nbsp"></span>  
          window:splitRatio="0.3"
          <br>
          <span class="nbsp"></span> 
          window:splitMinWidth="600dp">
          <br>
          <span class="nbsp"></span> 
          &lt;ActivityFilter 
          <br>
          <span class="nbsp"></span>  
          <span class="nbsp"></span> 
          window:activityName=".MainActivity"/>
        </p>
        <p class="p margin-l30">&lt;/SplitPlaceholderRule></p>
        <br>
        <p class="p margin-l30">
          &lt;ActivityRule
          <br>
          <span class="nbsp"></span> 
          window:alwaysExpand="true">
          <br>
          <span class="nbsp"></span> 
          &lt;ActivityFilter 
          <br>
          <span class="nbsp"></span>  
          <span class="nbsp"></span> 
          window:activityName=".FullScreenActivity"/>
        </p>
        <p class="p margin-l30">&lt;/ActivityRule></p>
      <p class="p ">&lt;/resources></p>
    </div>
    <p class="p margin-b10">
      For example, start activity C in situ in activity B without additional intent flag:
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image22.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image22.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      The result is that the stacking order of Activities in the same task is as follows:
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image23.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image23.png',
        ]"
      >
      </el-image>
    </p>

    <p class="p margin-b20">
      The navigation mode: homepage is fixed on the left, and secondary,  tertiary and other sub-grade pages are stacked on the right.
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="2-3-3-2">
      2.3.3.2 Multiple split screen
    </h4>
    <p class="p margin-b20">
      Multi screen is namely shopping mode.
    </p>
    <div class="code margin-b20">
      <p class="p ">&lt;resources xmlns:window="http://schemas.android.com/apk/res-auto"></p>  
        <p class="p margin-l30">&lt;!-- Automatically split the following activity pairs. --></p>
        <p class="p margin-l30">
          &lt;SplitPairRule
          <br>
          <span class="nbsp"></span>  
          window:splitRatio="0.3"
          <br>
          <span class="nbsp"></span>  
          window:splitMinWidth="600dp"
          <br>
          <span class="nbsp"></span>
          window:finishPrimaryWithSecondary="false"
          <br>
          <span class="nbsp"></span> 
          window:finishSecondaryWithPrimary="true"
          <br>
          <span class="nbsp"></span>
          window:clearTop="true">
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".MainActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName=".SecondActivity"/>
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".MainActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName=".ThirdActivity"/>
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".ThirdActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName="*/*"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityAction="android.intent.action.VIEW"/>
        </p>
        <p class="p margin-l30">&lt;/SplitPairRule></p>
        <br>
        <p class="p margin-l30">&lt;!--  Automatically launch a placeholder for the list activity. --></p>
        <br>
        <p class="p margin-l30">
          &lt;SplitPlaceholderRule
          <br>
          <span class="nbsp"></span>  
          window:placeholderActivityName=".SplitActivityPlaceholder"
          <br>
          <span class="nbsp"></span>  
          window:splitRatio="0.3"
          <br>
          <span class="nbsp"></span> 
          window:splitMinWidth="600dp">
          <br>
          <span class="nbsp"></span> 
          &lt;ActivityFilter 
          <br>
          <span class="nbsp"></span>  
          <span class="nbsp"></span> 
          window:activityName=".MainActivity"/>
        </p>
        <p class="p margin-l30">&lt;/SplitPlaceholderRule></p>
        <br>
        <p class="p margin-l30">
          &lt;ActivityRule
          <br>
          <span class="nbsp"></span> 
          window:alwaysExpand="true">
          <br>
          <span class="nbsp"></span> 
          &lt;ActivityFilter 
          <br>
          <span class="nbsp"></span>  
          <span class="nbsp"></span> 
          window:activityName=".FullScreenActivity"/>
        </p>
        <p class="p margin-l30">&lt;/ActivityRule></p>
      <p class="p ">&lt;/resources></p>
    </div>
    <p class="p margin-b20">
      For example, activity B starts activity C on the side and makes the split screen move to one side: 
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image8.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image8.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      The result is that the stacking order of activities in the same task is as follows:
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image9.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image9.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      There is no fixed homepage on the left side in the shopping mode.
    </p>
    <h3 class="h3 margin-t20 margin-b20 nav-title" id="2-3-4">
      2.3.4 Description and attentions
    </h3>
    <p class="p">
      Activities in new task
    </p>
    <p class="p margin-b20">
      When the activity in the split screen task window starts the activities in a new task, the new task will be separated from the task containing the split screen and is displayed in full window. The Recent Apps screen will display two tasks: the task in the split screen and the new task.
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image10.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image10.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p">
      Start activity C in the new task from activity B.
    </p>
    <p class="p margin-b20">
      <b>There is a limitation: there must be no B | C configuration rules in Split PairFilter </b>
    </p>
    <p class="p">
      State change of responsive split screen
    </p>
    <p class="p margin-b20">
      SplitInfo contains the changes of the contents of the primary and secondary stacks.
    </p>
    <div class="code margin-b20">
      <p class="p ">
          SplitController.getInstance().addSplitListener(this,
          <br>
          ContextCompat.getMainExecutor(this), 	new Consumer&lt;List&lt;SplitInfo>>() {
      </p>
        <p class="p padding-l30">@Override</p>
        <p class="p padding-l30"> public void accept(List&lt;SplitInfo> splitInfos) {</p>
        <p class="p padding-l30">}</p>
      <p class="p ">});</p>
    </div>
    <p class="p margin-b10">
      Restrictions and attentions
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>Cross application split screen is not supported
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>All activities must be in the same task
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>Only activities in the same process can be organized and placed in split screens
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>About application orientation
    </p>
    <p class="p margin-l30">
      If the App only supports portrait screen, split screen cannot be started during landscape screen. The App needs to support landscape screen. The App can dynamically set whether to support landscapeen or only vertical screen in onCreate and onConfigurationChanged according to the result that whether the ratio of long and short sides of the screen exceeds 9/16.
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>Support resizable
    </p>
    <p class="p margin-l30">
      If android: resizableActivity="false" is configured, it will result in the failure of split screen display.
    </p>
  </div>
</template>

<script>
export default {
  props: ['scrollContainer'],
  data() {
    return {
    };
  },
  created() {
    
  },
};
</script>

<style scoped lang="scss">
.h1 {
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: bold;
}
.h2 {
  font-size: 22px;
  color: rgb(64, 158, 255);
  font-weight: bold;
}
.h3 {
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-weight: bold;
}
.h4 {
  font-size: 17px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.h5 {
  font-size: 16px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.p {
  display: block;
  font-size: 16px;
  color: rgb(89, 89, 89);
  line-height: 28px;
}
.p-small {
  display: block;
  font-size: 13px;
  color: rgb(89, 89, 89);
  line-height: 24px;
}
.a {
  color: rgb(64, 158, 255);
}
.w400 {
  width: 400px;
}
.w600 {
  display: block;
  width: 600px;
}
.w750 {
  display: block;
  width: 750px;
}
.code {
  padding: 20px 40px;
  border: 1px solid #f29d1e;
  background-color: #ffffcc;
}
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #000;
    padding: 10px;
  }
}
ul {
  li {
    list-style: disc;
  }
}
</style>