<template>
  <div class="view-box">
    <h1 class="h1 text-center ">
      金标联盟Activity嵌入适配指导
    </h1>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="1">
      一、背景
    </h2>
    <p class="p margin-b10">
      Android从12L开始支持通过Jetpack Window实现activity嵌入功能，对于大屏体验有很大的提高。vivo从12L（包括T版本）、OPPO和小米从T版本开始，都支持Jetpack Window实现activity嵌入，希望开发者尽快适配android activity嵌入方案，提高应用在大屏设备的用户体验。在阅读适配文档时，建议开发者结合android WindowManager demo一起理解，便于尽快熟悉activity嵌入新功能。
    </p>
    <p class="p margin-b10">
      Jetpack WindowManager demo
    </p>
    <p class="p margin-b10">
      <a
        class="a"
        href="https://github.com/android/user-interface-samples/tree/main/WindowManager"
      >
        <i class="el-icon-paperclip"></i>
        https://github.com/android/user-interface-samples/tree/main/WindowManager
      </a>
    </p>
    <p class="p margin-b10">
      Android activity嵌入适配指导
    </p>
    <p class="p margin-b10">
      <a
        class="a"
        href="https://developer.android.com/guide/topics/large-screens/activity-embedding"
      >
        <i class="el-icon-paperclip"></i>
        https://developer.android.com/guide/topics/large-screens/activity-embedding
      </a>
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="2">
      二、 activity嵌入适配
    </h2>

    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-1">
      2.1 优势和用途
    </h3>
    <p class="p margin-b10 padding-l30">
      优势
    </p>
    <p class="p margin-b10 padding-l30">
      ● activity 嵌入只需要对应用进行很少的重构或根本不需要对应用进行重构。您通过创建 XML 配置文件或进行 Jetpack WindowManager API 调用来确定应用如何显示其 activity（并排或堆叠）。
    </p>
    <p class="p margin-b10 padding-l30">
      ● 系统会自动维护对小屏幕的支持。当应用在配备小屏幕的设备上时，activity 会相互堆叠。在大屏幕上，activity 会并排显示。系统会根据您已创建的配置（不需要分支逻辑）来确定呈现方式。</p>
    <p class="p margin-b10 padding-l30">
      ● activity 嵌入支持设备屏幕方向的变化，并且可以在可折叠设备上无缝工作，该功能会随着设备折叠和展开而堆叠和取消堆叠 activity。
    </p>
    <p class="p margin-b10 padding-l30">
      用途
    </p>
    <p class="p margin-b10 padding-l30">
      同时显示两个activity，跟随屏幕宽度改变（如折叠展开）自动相互堆叠或并排显示
    </p>
    <p class="p margin-b10 padding-l30">
      运行时检查是否支持
    </p>
    <div class="code margin-b10 margin-l30">
      <p class="p ">SplitController.getInstance().isSplitSupported();</p>  
    </div>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-2">
      2.2 拆分窗口效果展示
    </h3>
    <p class="p margin-b10">
      A和B同时启动
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image1.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image1.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      全屏的A启动B，A和B并排显示
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image2.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image2.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      A、 B启动后，再启动C到右侧（类似应用多窗的导航模式）
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image3.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image3.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      A、 B启动后，再启动C此时B移到primary位置（类似应用多窗的购物模式）
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image4.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image4.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      A、 B启动后，再启动C，C全屏展示（可用于全屏播放视屏等）
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image5.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image5.png',
        ]"
      >
      </el-image>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-3">
      2.3 适配指导
    </h3>
    <h3 class="h3 margin-t20 margin-b20 nav-title" id="2-3-1">
      2.3.1 IDE要求和接入步骤
    </h3>
    <p class="p margin-b10">
      IDE版本要求
    </p>
    <p class="p margin-b20">
      Android Studio Chipmunk | 2021.2.1 或更高版本
    </p>
    <p class="p margin-b10">
      在应用的 build 文件中添加库依赖项
    </p>
    <p class="p margin-b20">
      WindowManager 库和Jetpack Startup 库
    </p>
    <div class="code margin-b20">
      <p class="p ">implementation("androidx.window:window:1.1.0-alpha02")</p>
      <p class="p ">implementation("androidx.startup:startup-runtime:1.1.1")</p>
    </div>
    <p class="p margin-b20">
      添加配置文件res/xml/main_split_config.xml
    </p>
    <div class="code margin-b20">
      <p class="p ">
        &lt;resources 
      </p>
        <p class="p padding-l30">xmlns:window="http://schemas.android.com/apk/res-auto"></p>
        <p class="p padding-l30">&lt;SplitPairRule  </p>
          <p class="p padding-l60">window:splitRatio="0.5"</p>
          <p class="p padding-l60">window:splitMinWidth="300dp"></p>
          <p class="p padding-l60">&lt;SplitPairFilter </p>
          <p class="p padding-l90">window:primaryActivityName=".PrimaryAct"</p>
          <p class="p padding-l90">window:secondaryActivityName=".Secondary1"/></p>
        <p class="p padding-l30">&lt;/SplitPairRule></p>
      <p class="p ">&lt;/resources></p>
    </div>
    <p class="p margin-b10">
      AndroidManifest.xml添加InitializationProvider
    </p>
    <p class="p margin-b10">
      备注：若提示找不到tools标签，需要添加xmlns
    </p>
    <p class="p margin-b20">
      xmlns:tools="http://schemas.android.com/tools"
    </p>
    <div class="code margin-b20">
      <p class="p ">&lt;provider</p>
        <p class="p padding-l30">android:name="androidx.startup.InitializationProvider" </p>
        <p class="p padding-l30">android:authorities="${applicationId}.androidx-startup" </p>
        <p class="p padding-l30">android:exported="false" </p>
        <p class="p padding-l30">tools:node="merge"> </p>

        <p class="p padding-l30">&lt;!-- This entry makes ExampleWindowInitializer discoverable. --> </p>
        <p class="p padding-l30"> &lt; meta-data </p>
          <p class="p padding-l60">android:name="androidx.window.sample.embedding.ExampleWindowInitializer"</p>
          <p class="p padding-l60">android:value="androidx.startup" /> </p>
      <p class="p ">&lt;/provider></p>
    </div>
    <p class="p margin-b20">
      实现自己的嵌入加载器ExampleWindowInitializer
    </p>
    <div class="code margin-b10">
      <p class="p ">
          public class ExampleWindowInitializer implements Initializer&lt;SplitController> {
      </p>
        <p class="p padding-l30">@Override</p>
        <p class="p padding-l30">public SplitController create(Context context) {</p>
          <p class="p padding-l60"> 
            SplitController.initialize(context, R.xml.main_split_config);
          </p>
          <p class="p padding-l60"> 
            return SplitController.getInstance();
          </p>
        <p class="p padding-l30">}</p>
        <br>
        <p class="p padding-l30">@Override</p>
        <p class="p padding-l30">public List&lt;Class&lt;? extends Initializer&lt;?>>> dependencies() {</p>
          <p class="p padding-l60"> 
            return emptyList();
          </p>
        <p class="p padding-l30">}</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b20">
      运行app即可看到PrimaryAct与Secondary1左右并排显示
    </p>
    <h3 class="h3 margin-t20 margin-b20 nav-title" id="2-3-2">
      2.3.2 参数解析
    </h3>
    <table class="p  margin-b30" cellspacing="0" cellpadding="0">
      <tr>
        <th>
          <p>参数</p>
        </th>
        <th >
          <p>描述</p>
        </th>
      </tr>
      <tr>
        <td>
          <p><b>SplitPairRule</b></p>
        </td>
        <td >
          <p><b>用来描述分屏配对情景（两侧容器均有具体activity），对应SplitPairFilter</b></p>
        </td>
      </tr>
      <tr>
        <td>
          <p>splitRatio	</p>
        </td>
        <td >
          <p>分屏比默认为0.5f，即左右1：1分屏</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>splitMinWidth	</p>
        </td>
        <td >
          <p>默认为0建议配置600dp，意思是宽度达到600dp门限才可以分屏主窗口可分屏显示的最小窗口宽度</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>finishPrimaryWithSecondary		</p>
        </td>
        <td >
          <p>取值”never” “always” “adjacent”，默认为nerver，always：若secondary container中所有activity都finish，则primary container中创建分屏的activity也会finish，不推荐应用主动配置此项。</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>finishSecondaryWithPrimary		</p>
        </td>
        <td >
          <p>取值”never” “always” “adjacent”，默认为always，always：若primary container中所有activity都finish，则secondary container中所有activity也会finish，不推荐应用主动配置此项。</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>clearTop		</p>
        </td>
        <td >
          <p>取值”true”或false默认为false true：启动activity窗口分屏，存在相同的primary container，若新建secondary container，则原secondary container中的activity会被finish掉，推荐应用配置，避免右分屏出现多实例</p>
        </td>
      </tr>
      <tr>
        <td>
          <p><b>SplitPairFilter</b>		</p>
        </td>
        <td >
          <p>分屏配对关系（目前google仅支持枚举所有配对关系，不支持通配符）</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>primaryActivityName		</p>
        </td>
        <td >
          <p>分屏的primay activity component name</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>secondaryActivityName		</p>
        </td>
        <td >
          <p>分屏的secondary activity component name</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>secondaryActivityAction		</p>
        </td>
        <td >
          <p>分屏的secondary activity 启动的action</p>
        </td>
      </tr>
    </table>
    <table class="p  margin-b30" cellspacing="0" cellpadding="0">
      <tr>
        <th>
          <p>SplitPlaceholderRule	</p>
        </th>
        <th >
          <p>用来描述分屏下的占位符情景，对应ActivityFilter</p>
        </th>
      </tr>
      <tr>
        <td>
          <p>placeholderActivityName</p>
        </td>
        <td >
          <p>A启动B还没有启动此时placeholderActivityName对应的activity占据secondary位置</p>
        </td>
      </tr>
      <tr>
        <td>
          <p><b>ActivityFilter</b>		</p>
        </td>
        <td >
          <p>适配该规则的activity</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>componentName		</p>
        </td>
        <td >
          <p>组件名</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>intentAction			</p>
        </td>
        <td >
          <p>intent</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>splitRatio			</p>
        </td>
        <td >
          <p>分屏比默认为0.5f，即左右1：1分屏</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>splitMinWidth		</p>
        </td>
        <td >
          <p>默认为0建议配置600dp，意思是宽度达到600dp门限才可以分屏主窗口可分屏显示的最小窗口宽度</p>
        </td>
      </tr>
    </table>
    <table class="p  margin-b30" cellspacing="0" cellpadding="0">
      <tr>
        <th>
          <p>ActivityRule		</p>
        </th>
        <th >
          <p>用来特别需要全屏显示的特例activity，对应ActivityFilter</p>
        </th>
      </tr>
      <tr>
        <td>
          <p>alwaysExpand	</p>
        </td>
        <td >
          <p>取值”true”或false true：启动的activity全屏显示</p>
        </td>
      </tr>
      <tr>
        <td>
          <p><b>ActivityFilter	</b>		</p>
        </td>
        <td >
          <p>适配该规则的activity</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>componentName		</p>
        </td>
        <td >
          <p>组件名</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>intentAction			</p>
        </td>
        <td >
          <p>intent</p>
        </td>
      </tr>
    </table>
    <h3 class="h3 margin-t20 margin-b20 nav-title" id="2-3-3">
      2.3.3 示例配置
    </h3>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="2-3-3-1">
      2.3.3.1 深层链接分屏
    </h4>
    <p class="p margin-b20">
      深层链接分屏即平行视窗的导航模式（带占位符页面SplitPlaceholderRule）
    </p>
    <div class="code margin-b20">
      <p class="p ">&lt;resources </p>  
        <p class="p margin-l30">xmlns:window="http://schemas.android.com/apk/res-auto"></p>
        <p class="p margin-l30">&lt;!-- Automatically split the following activity pairs. --></p>
        <p class="p margin-l30">
          &lt;SplitPairRule
          <br>
          <span class="nbsp"></span>  
          window:splitRatio="0.3"
          <br>
          <span class="nbsp"></span>  
          window:splitMinWidth="600dp"
          <br>
          <span class="nbsp"></span>
          window:finishPrimaryWithSecondary="false"
          <br>
          <span class="nbsp"></span> 
          window:finishSecondaryWithPrimary="true"
          <br>
          <span class="nbsp"></span>
          window:clearTop="true">
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".MainActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName=".SecondActivity"/>
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".MainActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName=".ThirdActivity"/>
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName="*"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName="*/*"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityAction="android.intent.action.VIEW"/>
        </p>
        <p class="p margin-l30">&lt;/SplitPairRule></p>
        <br>
        <p class="p margin-l30">&lt;!--  Automatically launch a placeholder for the list activity. --></p>
        <p class="p margin-l30">
          &lt;SplitPlaceholderRule
          <br>
          <span class="nbsp"></span>  
          window:placeholderActivityName=".SplitActivityPlaceholder"
          <br>
          <span class="nbsp"></span>  
          window:splitRatio="0.3"
          <br>
          <span class="nbsp"></span> 
          window:splitMinWidth="600dp">
          <br>
          <span class="nbsp"></span> 
          &lt;ActivityFilter 
          <br>
          <span class="nbsp"></span>  
          <span class="nbsp"></span> 
          window:activityName=".MainActivity"/>
        </p>
        <p class="p margin-l30">&lt;/SplitPlaceholderRule></p>
        <br>
        <p class="p margin-l30">
          &lt;ActivityRule
          <br>
          <span class="nbsp"></span> 
          window:alwaysExpand="true">
          <br>
          <span class="nbsp"></span> 
          &lt;ActivityFilter 
          <br>
          <span class="nbsp"></span>  
          <span class="nbsp"></span> 
          window:activityName=".FullScreenActivity"/>
        </p>
        <p class="p margin-l30">&lt;/ActivityRule></p>
      <p class="p ">&lt;/resources></p>
    </div>
    <p class="p margin-b10">
      比如在activity B 原位启动 activity C，并且没有额外的 intent 标志：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image22.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image22.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10">
      结果是同一任务中 activity 的叠置顺序如下：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image23.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image23.png',
        ]"
      >
      </el-image>
    </p>

    <p class="p margin-b20">
      导航模式是左侧固定主页，右侧堆叠二级、三级等子级页面。
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="2-3-3-2">
      2.3.3.2 多重分屏
    </h4>
    <p class="p margin-b20">
      多重分屏即购物模式
    </p>
    <div class="code margin-b20">
      <p class="p ">&lt;resources xmlns:window="http://schemas.android.com/apk/res-auto"></p>  
        <p class="p margin-l30">&lt;!-- Automatically split the following activity pairs. --></p>
        <p class="p margin-l30">
          &lt;SplitPairRule
          <br>
          <span class="nbsp"></span>  
          window:splitRatio="0.3"
          <br>
          <span class="nbsp"></span>  
          window:splitMinWidth="600dp"
          <br>
          <span class="nbsp"></span>
          window:finishPrimaryWithSecondary="false"
          <br>
          <span class="nbsp"></span> 
          window:finishSecondaryWithPrimary="true"
          <br>
          <span class="nbsp"></span>
          window:clearTop="true">
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".MainActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName=".SecondActivity"/>
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".MainActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName=".ThirdActivity"/>
        </p>
        <p class="p margin-l30">
          &lt;SplitPairFilter
          <br>
          <span class="nbsp"></span>  
          window:primaryActivityName=".ThirdActivity"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityName="*/*"
          <br>
          <span class="nbsp"></span>  
          window:secondaryActivityAction="android.intent.action.VIEW"/>
        </p>
        <p class="p margin-l30">&lt;/SplitPairRule></p>
        <br>
        <p class="p margin-l30">&lt;!--  Automatically launch a placeholder for the list activity. --></p>
        <br>
        <p class="p margin-l30">
          &lt;SplitPlaceholderRule
          <br>
          <span class="nbsp"></span>  
          window:placeholderActivityName=".SplitActivityPlaceholder"
          <br>
          <span class="nbsp"></span>  
          window:splitRatio="0.3"
          <br>
          <span class="nbsp"></span> 
          window:splitMinWidth="600dp">
          <br>
          <span class="nbsp"></span> 
          &lt;ActivityFilter 
          <br>
          <span class="nbsp"></span>  
          <span class="nbsp"></span> 
          window:activityName=".MainActivity"/>
        </p>
        <p class="p margin-l30">&lt;/SplitPlaceholderRule></p>
        <br>
        <p class="p margin-l30">
          &lt;ActivityRule
          <br>
          <span class="nbsp"></span> 
          window:alwaysExpand="true">
          <br>
          <span class="nbsp"></span> 
          &lt;ActivityFilter 
          <br>
          <span class="nbsp"></span>  
          <span class="nbsp"></span> 
          window:activityName=".FullScreenActivity"/>
        </p>
        <p class="p margin-l30">&lt;/ActivityRule></p>
      <p class="p ">&lt;/resources></p>
    </div>
    <p class="p margin-b20">
      比如activity B 在侧面启动 activity C，并使分屏向一旁位移：
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image8.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image8.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      结果是同一任务中 activity 的叠置顺序如下：
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image9.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image9.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      购物模式左侧无固定主页。
    </p>
    <h3 class="h3 margin-t20 margin-b20 nav-title" id="2-3-4">
      2.3.4 说明和注意事项
    </h3>
    <p class="p">
      新任务中的 activity
    </p>
    <p class="p margin-b20">
      当分屏任务窗口中的 activity 启动新任务中的 activity 时，新任务将与包含分屏的任务分开并显示在全窗口中。“最近使用的应用”屏幕将显示两项任务：分屏中的任务和新任务。
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image10.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image10.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p">
      从activity B启动新任务中的activity C。
    </p>
    <p class="p margin-b20">
      <b>有个限制，SplitPairFilter中不能有B|C配置规则</b>
    </p>
    <p class="p">
      响应分屏状态变化
    </p>
    <p class="p margin-b20">
      SplitInfo包含primary和secondary栈内容的变化
    </p>
    <div class="code margin-b20">
      <p class="p ">
          SplitController.getInstance().addSplitListener(this,
          <br>
          ContextCompat.getMainExecutor(this), 	new Consumer&lt;List&lt;SplitInfo>>() {
      </p>
        <p class="p padding-l30">@Override</p>
        <p class="p padding-l30"> public void accept(List&lt;SplitInfo> splitInfos) {</p>
        <p class="p padding-l30">}</p>
      <p class="p ">});</p>
    </div>
    <p class="p margin-b10">
      限制条件和注意事项
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>不支持跨应用分屏
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>所有Activity均需在同一Task中
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>只能将同一进程中的 activity 组织和放置在分屏中
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>关于应用orientation
    </p>
    <p class="p margin-l30">
      若应用仅支持竖屏，则横屏时无法启动分屏，需要应用支持横屏，应用可根据屏幕长短边比例是否超过9/16来在onCreate和onConfigurationChanged中动态设定是否支持旋转屏还是仅支持竖屏
    </p>
    <p class="p ">
      ●<span class="margin-l20"></span>支持resizeable
    </p>
    <p class="p margin-l30">
      若配置android:resizeableActivity=“false”，会导致无法分屏显示
    </p>
  </div>
</template>

<script>
export default {
  props: ['scrollContainer'],
  data() {
    return {
    };
  },
  created() {
    
  },
};
</script>

<style scoped lang="scss">
.h1 {
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: bold;
}
.h2 {
  font-size: 22px;
  color: rgb(64, 158, 255);
  font-weight: bold;
}
.h3 {
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-weight: bold;
}
.h4 {
  font-size: 17px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.h5 {
  font-size: 16px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.p {
  display: block;
  font-size: 16px;
  color: rgb(89, 89, 89);
  line-height: 28px;
}
.p-small {
  display: block;
  font-size: 13px;
  color: rgb(89, 89, 89);
  line-height: 24px;
}
.a {
  color: rgb(64, 158, 255);
}
.w400 {
  width: 400px;
}
.w600 {
  display: block;
  width: 600px;
}
.w750 {
  display: block;
  width: 750px;
}
.code {
  padding: 20px 40px;
  border: 1px solid #f29d1e;
  background-color: #ffffcc;
}
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #000;
    padding: 10px;
  }
}
ul {
  li {
    list-style: disc;
  }
}
</style>